import * as React from "react"
import { Link } from "gatsby"
import SEO from '../components/seo'

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  textAlign: "center",
  justifyContent: "center",
  height: "90vh",
  marginBottom: 10,
  backgroundColor: '#ffffff',
}
const headingStyles = {
  marginBottom: 10,
  marginTop: 10
}
const buttonStyles = {
  marginTop: 48,
  padding: '10px 15px 10px 15px', 
  borderRadius: '4px', 
  border: '1px solid gray',
  textDecoration: 'none'
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <SEO title='404: Ikke funnet' />
      <h1 style={headingStyles}>Siden ble ikke funnet</h1>
      <p>
        Beklager{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        innholdet kan være flyttet.
        <br />
        <br />
        <br />
        <Link style={buttonStyles} id="404BackToHome" to="/">Gå til forsiden</Link>
      </p>
    </main>
  )
}

export default NotFoundPage
